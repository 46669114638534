import React, { useState, useEffect } from 'react';
import { FaGooglePlay, FaApple, FaEnvelope, FaPhone } from 'react-icons/fa';
import mee3ad2 from './assets/pictures/mee3ad2.png';
import mee3ad3 from './assets/pictures/mee3ad1.png';
import logo_black from './assets/pictures/logo_black.png';
import './App.css';

const images = [mee3ad2, mee3ad3]; 

const App = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [showIntro, setShowIntro] = useState(true);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentImage((prev) => (prev + 1) % images.length);
    }, 5500);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const introTimer = setTimeout(() => {
      setShowIntro(false);
    }, 5000);

    return () => clearTimeout(introTimer);
  }, []);

  const handleDotClick = (index) => {
    setCurrentImage(index); 
  };

  return (
    <div className="bg-with-line min-h-screen text-white relative font-montserrat">
      <header className="relative z-10 flex flex-col items-center justify-center pt-5">
        <img
          src={logo_black}
          alt="Mee3ad Logo"
          className="h-18 w-16 md:h-24 md:w-24 animate-bounce mb-4 mt-10"
        />
        <h1 className="text-3xl md:text-4xl font-bold text-gold text-center mb-6">
          We are coming soon!
        </h1>
        <p className="text-base md:text-lg text-gold text-center animate-pulse">
          Stay tuned for something amazing
        </p>
      </header>

      {/* Scrollable Image Section */}
      {!showIntro && (
        <div className="relative z-10 flex flex-col items-center justify-center h-auto md:h-screen mt-10">
          <div className="w-full relative">
            <div className="flex justify-center">
              <img
                src={images[currentImage]}
                alt="Mee3ad Feature"
                className="w-[70%] md:w-[50%] h-auto object-cover rounded-lg shadow-lg transform transition duration-700 hover:scale-105"
                loading="lazy"
              />
            </div>

            <div className="absolute bottom-5 w-full flex justify-center space-x-2 mt-4">
              {images.map((_, index) => (
                <div
                  key={index}
                  onClick={() => handleDotClick(index)}
                  className={`cursor-pointer w-4 h-1 md:w-6 md:h-2 rounded-full transition ${
                    currentImage === index ? 'bg-gold' : 'bg-gray-400'
                  }`}
                ></div>
              ))}
            </div>
          </div>
        </div>
      )}

      {/* App Store and Google Play Icons */}
      <div className="fixed bottom-10 left-5 md:left-10 flex flex-col items-center space-y-3 ">
        <span className="text-gold text-sm md:text-base">Download here</span>
        <div className="flex items-center space-x-4 md:space-x-8">
          <a href="https://www.apple.com/app-store" target="_blank" rel="noopener noreferrer" className="text-gold flex flex-col items-center">
            <FaApple className="text-3xl md:text-4xl hover:scale-110 transition-transform duration-300" />
            <p className="text-xs md:text-sm mt-1">App Store</p>
          </a>
          <a href="https://play.google.com/store" target="_blank" rel="noopener noreferrer" className="text-gold flex flex-col items-center">
            <FaGooglePlay className="text-3xl md:text-4xl hover:scale-110 transition-transform duration-300" />
            <p className="text-xs md:text-sm mt-1">Google Play</p>
          </a>
        </div>
      </div>

      {/* Contact Us Section */}
      <div className="fixed bottom-10 right-5 md:right-10 flex flex-col items-center space-y-3">
        <span className="text-gold text-sm md:text-base">Contact Us</span>
        <div className="flex items-center space-x-4 md:space-x-6">
          {/* Email Icon */}
          <a href="mailto:info@mee3ad.com" className="text-gold flex flex-col items-center">
            <FaEnvelope className="text-3xl md:text-4xl hover:scale-110 transition-transform duration-300" />
            <p className="text-xs md:text-sm mt-1">info@mee3ad.com</p>
          </a>
          {/* Phone Icon */}
          <a href="tel:+970566331330" className="text-gold flex flex-col items-center">
            <FaPhone className="text-3xl md:text-4xl hover:scale-110 transition-transform duration-300" />
            <p className="text-xs md:text-sm mt-1">+970566331330</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default App;
